@font-face {
  font-family: 'custom-icons';
  font-style: normal;
  font-weight: normal;
  src: url('../font/custom-icons.eot?81368252');
  src:
    url('../font/custom-icons.eot?81368252#iefix') format('embedded-opentype'),
    url('../font/custom-icons.woff2?81368252') format('woff2'),
    url('../font/custom-icons.woff?81368252') format('woff'),
    url('../font/custom-icons.ttf?81368252') format('truetype'),
    url('../font/custom-icons.svg?81368252#custom-icons') format('svg');
}
